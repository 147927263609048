<template>
	<div class="main">
		<!-- <div class="location">
			<div class="cur-location">
				<span style="line-height: 1">您所在的位置：</span>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>用户管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->

		<common-header
			:formModel="formInline"
			@query="query"
			@resetTime="formInline.param.cuUser = ''"
			class="common_header"
		>
			<template v-slot:form>
				<el-form-item :label="$t('user.name')" prop="param.name">
					<el-input v-model="formInline.param.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.userName')" prop="param.userName">
					<el-input v-model="formInline.param.userName"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.phoneNo')" prop="param.phoneNo">
					<el-input v-model="formInline.param.phoneNo"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.cuName')" prop="param.cuName">
					<el-input v-model="formInline.param.cuName"></el-input>
				</el-form-item>
			</template>
			<template v-slot:tools>
				<tools-btn left-icon="add" @click.native="openDialogVisible()">{{ $t('common.add') }}</tools-btn>
			</template>
		</common-header>
		<div class="table tableHeight">
			<el-table border stripe size="mini" height="100%" :data="tableData">
				<el-table-column show-overflow-tooltip prop="name" :label="$t('user.name')" width="140px">
					<template v-slot="{ row }">
						<div v-if="row.count > 0" class="el-button--text" @click="filter(row)">
							{{ row.name }}
						</div>
						<div v-else>
							{{ row.name }}
						</div>
					</template>
				</el-table-column>
				<el-table-column
					show-overflow-tooltip
					prop="roleName"
					:label="$t('user.roleName')"
					width="130px"
				></el-table-column>
				<el-table-column
					show-overflow-tooltip
					prop="userName"
					:label="$t('user.userName')"
					width="100px"
				></el-table-column>
				<el-table-column show-overflow-tooltip prop="phoneNo" :label="$t('user.phoneNo')"></el-table-column>
				<!-- <el-table-column show-overflow-tooltip prop="cuUser" :label="$t('user.cuUser')"></el-table-column> -->
				<el-table-column
					show-overflow-tooltip
					prop="certificateCode"
					:label="$t('user.certificateCode')"
				></el-table-column>
				<el-table-column show-overflow-tooltip prop="roleName" :label="$t('user.roleName')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="status" :label="$t('user.status')">
					<template v-slot="{ row }">
						{{ row.status | mapLabel('sysUserStatus') }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="count" :label="$t('user.sonCount')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="wxStatus" :label="$t('user.wxStatus')">
					<template v-slot="{ row }">
						{{ row.wxStatus | mapLabel('wxStatus') }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="authApplyNoConfirm" :label="$t('user.authApplyNoConfirm')">
					<template v-slot="{ row }">
						{{ row.authApplyNoConfirm | mapLabel('openCloseStatus') }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="note" :label="$t('user.note')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="cuName" :label="$t('user.cuName')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="cuTime" :label="$t('user.cuTime')">
					<template v-slot="{ row }">
						<span v-if="row.cuTime">
							{{ row.cuTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
						</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('common.operation')" width="140">
					<template v-slot="{ row }">
						<el-button size="mini" type="text" @click="openDialogVisible(row)">
							{{ $t('common.edit') }}
						</el-button>
						<el-dropdown trigger="click" style="margin-left: 10px" @command="handleCommand">
							<el-button size="mini" type="text">
								{{ $t('common.moreOperate') }}
								<i class="el-icon-arrow-down el-icon--right"></i>
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="beforeHandleCommand(row, 'role')">
									{{ $t('user.configRole') }}
								</el-dropdown-item>
								<el-dropdown-item :command="beforeHandleCommand(row, 'area')">
									{{ $t('user.configArea') }}
								</el-dropdown-item>
								<el-dropdown-item :command="beforeHandleCommand(row, 'house')">
									{{ $t('user.configHouse') }}
								</el-dropdown-item>
								<el-dropdown-item :command="beforeHandleCommand(row, 'resetPwd')">
									{{ $t('user.resetPwd') }}
								</el-dropdown-item>
								<el-dropdown-item :command="beforeHandleCommand(row, 'del')">
									{{ $t('common.del') }}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<gl-page
			@size-change="sizeChange"
			@current-change="query"
			:current-page="formInline.pageNum"
			:page-size="formInline.pageSize"
			:total="total"
		></gl-page>

		<el-dialog
			:title="dialogTitle"
			:visible.sync="dialogVisible"
			center
			width="700px"
			:before-close="closeDialogVisible"
		>
			<el-form
				ref="addEditForm"
				:model="addEditForm"
				:rules="addEditRules"
				label-width="90px"
				inline
				class="form"
			>
				<el-form-item :label="$t('user.name')" prop="name">
					<el-input v-model="addEditForm.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.userName')" prop="userName">
					<el-input v-model="addEditForm.userName"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.phoneNo')" prop="phoneNo">
					<el-input v-model="addEditForm.phoneNo"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.status')" prop="status">
					<el-select v-model="addEditForm.status" placeholder="请选择" style="width: 100%">
						<el-option
							v-for="item in sysUserStatus"
							:key="item.code"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('user.certificateCode')">
					<el-input v-model="addEditForm.certificateCode"></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.initPwd')" v-if="!addEditForm.userId">
					<el-input v-model="addEditForm.initPwd" type="password" show-password></el-input>
				</el-form-item>
				<el-form-item :label="$t('user.wxStatus')">
					<el-select v-model="addEditForm.wxStatus" placeholder="请选择" style="width: 100%">
						<el-option
							v-for="item in wxStatus"
							:key="item.code"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('user.authApplyNoConfirm')">
					<el-select v-model="addEditForm.authApplyNoConfirm" placeholder="请选择" style="width: 100%">
						<el-option
							v-for="item in openCloseStatus"
							:key="item.code"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>

				<el-form-item :label="$t('user.note')">
					<el-input type="textarea" v-model="addEditForm.note" class="textarea"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" style="padding-bottom: 5px">
				<el-button @click="closeDialogVisible">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="save()">{{ $t('common.confirm') }}</el-button>
			</span>
		</el-dialog>

		<el-dialog
			:title="dialogRoleTitle"
			:visible.sync="dialogRoleVisible"
			center
			width="500px"
			:before-close="closeDialogRoleVisible"
		>
			<div class="table">
				<el-table
					border
					stripe
					size="mini"
					ref="roleMultipleTable"
					:data="tableRoleData"
					style="width: 100%"
					height="400px"
					@selection-change="handleRoleSelectionChange"
				>
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column
						show-overflow-tooltip
						prop="roleName"
						:label="$t('user.roleName')"
						width="120"
					></el-table-column>
					<el-table-column show-overflow-tooltip prop="status" :label="$t('user.roleStatus')" width="80">
						<template v-slot="{ row }">
							{{ row.status | mapLabel('roleStatus') }}
						</template>
					</el-table-column>
					<el-table-column show-overflow-tooltip prop="note" :label="$t('user.roleNote')"></el-table-column>
				</el-table>
			</div>

			<span slot="footer" style="padding-bottom: 5px">
				<el-button @click="closeDialogRoleVisible">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="saveUserRole()">{{ $t('common.confirm') }}</el-button>
			</span>
		</el-dialog>

		<el-dialog
			:title="dialogAreaTitle"
			:visible.sync="dialogAreaVisible"
			center
			width="500px"
			:before-close="closeDialogAreaVisible"
		>
			<div style="height: 360px">
				<el-tree
					:props="areaTreeProps"
					:data="areaTreeData"
					ref="areas"
					node-key="areaId"
					show-checkbox
				></el-tree>
			</div>

			<span slot="footer" style="padding-bottom: 5px">
				<el-button @click="closeDialogAreaVisible">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="saveUserArea()">{{ $t('common.confirm') }}</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:title="$t('user.configHouse')"
			:visible.sync="dialogHouseVisible"
			center
			width="1000px"
			:before-close="closeDialogHouseVisible"
		>
			<div class="LeftRight">
				<div>
					<el-tree
						ref="tree"
						:data="houseTreeData"
						show-checkbox
						node-key="areaId"
						:props="houseProps"
						@check="houseNodeClick"
						@node-click="houseNodeClick"
					></el-tree>
				</div>
				<div>
					<common-header :formModel="houseForm" @query="houseQuery" class="common_header">
						<template v-slot:form>
							<el-form-item :label="$t('user.houseAddress')" prop="param.houseAddress">
								<el-input v-model="houseForm.param.houseAddress"></el-input>
							</el-form-item>
						</template>
					</common-header>
					<div class="table">
						<el-table
							border
							stripe
							size="mini"
							height="305px"
							ref="houseTable"
							:data="huoseTableData"
							@select="houseSelectionChange"
							@select-all="houseSelectionChange"
						>
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column
								show-overflow-tooltip
								prop="areaName"
								:label="$t('user.areaName')"
							></el-table-column>
							<el-table-column
								show-overflow-tooltip
								prop="houseAddress"
								:label="$t('user.houseAddress')"
							></el-table-column>
							<el-table-column
								show-overflow-tooltip
								prop="standardAddress"
								:label="$t('user.standardAddress')"
							></el-table-column>
						</el-table>
					</div>
					<gl-page
						@size-change="huoseSizeChange"
						@current-change="houseQuery"
						:current-page="houseForm.pageNum"
						:page-size="houseForm.pageSize"
						:total="houseTotal"
					></gl-page>
				</div>
			</div>
			<span slot="footer" style="padding-bottom: 5px">
				<el-button @click="closeDialogHouseVisible">{{ $t('common.cancel') }}</el-button>
				<el-button type="primary" @click="saveHoues">{{ $t('common.confirm') }}</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:title="$t('user.resetPwd')"
			:visible.sync="passwordVisible"
			width="450px"
			center
			:close-on-click-modal="false"
		>
			<el-form ref="passwordForm" :model="passwordForm" label-width="100px" :rules="rules">
				<el-form-item :label="$t('user.resetPwd')" prop="password">
					<el-input size="mini" type="password" show-password v-model="passwordForm.password"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="passwordVisible = false">取 消</el-button>
				<el-button type="primary" @click="resetPwd">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'user',
	components: {},
	data() {
		return {
			formInline: {
				pageNum: 1,
				pageSize: 10,
				param: {
					name: '',
					userName: '',
					phoneNo: '',
					cuUser: '',
					cuName: ''
				}
			},
			filterUser: '',
			addEditForm: {
				authApplyNoConfirm: '',
				certificateCode: '',
				name: '',
				note: '',
				phoneNo: '',
				status: '',
				userId: '',
				userName: '',
				initPwd: '',
				wxStatus: ''
			},
			resetArr: {
				authApplyNoConfirm: '',
				certificateCode: '',
				name: '',
				note: '',
				phoneNo: '',
				status: '',
				userId: '',
				userName: '',
				initPwd: '',
				wxStatus: ''
			},
			total: 0,
			tableData: [],
			dialogVisible: false,
			dialogTitle: '',
			time: '',
			sysUserStatus: [],
			wxStatus: [],
			openCloseStatus: [],
			rules: [],
			addEditRules: {
				name: [{ required: true, message: this.$t('common.PsEnter') + this.$t('user.name'), trigger: 'blur' }],
				userName: [
					{ required: true, message: this.$t('common.PsEnter') + this.$t('user.userName'), trigger: 'blur' }
				],
				phoneNo: [
					{ required: true, message: this.$t('common.PsEnter') + this.$t('user.phoneNo'), trigger: 'blur' }
				],
				status: [
					{ required: true, message: this.$t('common.PsSelect') + this.$t('user.status'), trigger: 'change' }
				]
			},

			tableRoleData: [],
			dialogRoleVisible: false,
			dialogRoleTitle: this.$t('user.configRole'),
			configRoleForm: {
				userId: '',
				roleIds: []
			},
			dialogAreaTitle: this.$t('user.configArea'),
			dialogAreaVisible: false,
			areaTreeData: [],
			areaTreeProps: {
				children: 'childrenList',
				label: 'areaName'
			},
			configAreaForm: {
				areaParam: [],
				userId: ''
			},
			dialogHouseVisible: false,
			houseForm: {
				pageNum: 1,
				pageSize: 10,
				param: {
					areaId: '',
					houseAddress: ''
				}
			},
			houseTotal: 0,
			huoseTableData: [],
			houseProps: {
				children: 'childrenList',
				label: 'areaName'
			},
			houseTreeData: [],
			houseSaveForm: {
				houseIds: [],
				userId: 0
			},
			passwordVisible: false,
			passwordForm: {
				id: '',
				password: ''
			}
		}
	},
	mounted() {
		var enums = this.$store.state.enum
		this.sysUserStatus = enums[`sysUserStatus`]
		this.wxStatus = enums[`wxStatus`]
		this.openCloseStatus = enums[`openCloseStatus`]
		this.query()
		this.getSysArea()
	},
	methods: {
		filter(row) {
			this.formInline.param.name = ''
			this.formInline.param.userName = ''
			this.formInline.param.phoneNo = ''
			this.filterUser = row
			this.formInline.param.cuName = row.name
			this.query()
		},
		houseSelectionChange(e) {
			console.log(e)
			this.houseSaveForm.houseIds = e.map(e => e.houseId)
		},
		getSysArea() {
			this.ajax.get('/admin/sys/user/getSysArea').then(res => {
				this.houseTreeData = res
			})
		},
		saveHoues() {
			if (this.houseSaveForm.houseIds.length == 0) {
				this.$message.error('请选择房屋')
				return
			}
			this.ajax.post('/admin/sys/user/saveUserHouse', this.houseSaveForm).then(res => {
				console.log(res)
				this.$message.success(this.$t('common.success'))
				this.closeDialogHouseVisible()
			})
		},
		houseNodeClick(e) {
			console.log(e)
			if (e.areaId && !e.childrenList) {
				this.$refs.tree.setCheckedKeys([e.areaId])
				this.houseForm.param.areaId = e.areaId
				this.houseQuery()
			} else {
				this.$refs.tree.setCheckedKeys([])
			}
		},
		huoseSizeChange(i) {
			this.houseForm.pageSize = i
			this.houseQuery()
		},
		houseQuery(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.houseForm.pageNum = i
			if (!this.houseForm.param.areaId) {
				this.$message.error('请先选择区域')
				return
			}
			this.houseForm.param.userId = this.houseSaveForm.userId
			this.ajax.post('/admin/sys/user/getHouseInfo', this.houseForm).then(res => {
				console.log(res)
				this.huoseTableData = res.list
				this.houseTotal = res.total
				this.$nextTick(() => {
					res.list.map(e => {
						if (e.check) {
							this.$refs.houseTable.toggleRowSelection(e, true)
						} else {
							this.$refs.houseTable.toggleRowSelection(e, false)
						}
					})
				})
			})
		},
		closeDialogHouseVisible() {
			this.dialogHouseVisible = false
		},
		beforeHandleCommand(row, command) {
			return {
				row: row,
				command: command
			}
		},
		handleCommand(command) {
			switch (
				command.command //角标index获取为command.index
			) {
				case 'house':
					this.dialogHouseVisible = true
					this.houseSaveForm.userId = command.row.userId
					break
				case 'role':
					this.configRole(command.row)
					break
				case 'area':
					this.configArea(command.row)
					break
				case 'resetPwd':
					this.passwordVisible = true
					this.passwordForm.id = command.row.userId
					this.passwordForm.password = ''
					// this.resetPwd(command.row)
					break
				case 'del':
					this.del(command.row)
					break
			}
		},
		sizeChange(i) {
			this.formInline.pageSize = i
			this.query()
		},
		openDialogVisible(row) {
			this.addEditForm = JSON.parse(JSON.stringify(this.resetArr))
			if (row) {
				Object.assign(this.addEditForm, row)
				this.dialogTitle = this.$t('common.edit') + this.$t('user.title')
			} else {
				this.dialogTitle = this.$t('common.add') + this.$t('user.title')
			}
			this.dialogVisible = true
		},
		closeDialogVisible() {
			this.dialogVisible = false
		},
		query(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.formInline.pageNum = i
			console.log(this.filterUser)
			if (this.filterUser) {
				if (this.formInline.param.cuName == this.filterUser.name) {
					this.formInline.param.cuUser = this.filterUser.userId
				} else {
					this.formInline.param.cuUser = ''
					this.filterUser = ''
				}
			}
			this.ajax.post('/admin/sys/user/pageInfo', this.formInline).then(res => {
				this.tableData = res.list
				this.total = res.total
			})
		},
		save() {
			this.$refs.addEditForm.validate(valid => {
				if (valid) {
					this.ajax.post('/admin/sys/user/saveSysUser', this.addEditForm).then(() => {
						this.$message({
							message: this.$t('common.success'),
							type: 'success'
						})
						this.closeDialogVisible()
						this.query()
					})
				}
			})
		},
		configRole(row) {
			this.ajax.get('/admin/sys/user/findSysRoleOptions').then(res => {
				this.tableRoleData = res
				res.forEach(e => {
					this.dialogRoleVisible = true
					if (e.roleId == row.roleId) {
						this.$nextTick(() => {
							this.$refs.roleMultipleTable.toggleRowSelection(e, true)
						})
					}
				})
				this.configRoleForm.userId = row.userId
			})
		},
		handleRoleSelectionChange(val) {
			console.log(val)
			if (val.length == 0) {
				return
			} else {
				this.configRoleForm.roleIds = []
				this.configRoleForm.roleIds.push(val[0].roleId)
				if (val.length > 1) {
					console.log('11111')
					this.$refs.roleMultipleTable.clearSelection()
					this.$refs.roleMultipleTable.toggleRowSelection(val.pop())
				}
			}
		},
		saveUserRole() {
			this.ajax.post('/admin/sys/user/saveSysUserInRole', this.configRoleForm).then(() => {
				this.$message({
					message: this.$t('common.success'),
					type: 'success'
				})
				this.dialogRoleVisible = false
				this.query()
			})
		},
		closeDialogRoleVisible() {
			this.dialogRoleVisible = false
		},

		configArea(row) {
			this.ajax.get('/admin/sys/user/getSysArea').then(res => {
				this.areaTreeData = res
				this.dialogAreaVisible = true
				this.configAreaForm.userId = row.userId
				this.getAreaByUser(row.userId)
			})
		},
		getAreaByUser(userId) {
			let that = this
			this.ajax.get('/admin/sys/user/findSysUserAreaPower?userId=' + userId).then(res => {
				this.$nextTick(() => {
					res.map(e => {
						let node = that.$refs.areas.getNode(e.areaId)
						if (e.areaPowerAll == 1 || node.isLeaf) {
							this.$refs.areas.setChecked(node, true)
						}
					})
				})
			})
		},
		saveUserArea() {
			var areaPowerAll0s = this.$refs.areas.getHalfCheckedNodes()
			var areaPowerAll1s = this.$refs.areas.getCheckedNodes()
			this.configAreaForm.areaParam = []
			areaPowerAll0s.forEach(e => {
				let a = {
					areaId: e.areaId,
					areaPowerAll: 0
				}
				this.configAreaForm.areaParam.push(a)
			})
			areaPowerAll1s.forEach(e => {
				let a = {
					areaId: e.areaId,
					areaPowerAll: 1
				}
				this.configAreaForm.areaParam.push(a)
			})
			this.ajax.post('/admin/sys/user/saveSysUserAreaPower', this.configAreaForm).then(() => {
				this.$message({
					message: this.$t('common.success'),
					type: 'success'
				})
				this.dialogAreaVisible = false
			})
		},

		closeDialogAreaVisible() {
			this.dialogAreaVisible = false
		},

		resetPwd() {
			this.$refs.passwordForm.validate(valid => {
				if (valid) {
					this.$confirm(this.$t('common.confirm') + this.$t('user.resetPwd') + '?', this.$t('common.Tips'), {
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.cancel'),
						closeOnClickModal: false,
						type: 'warning'
					}).then(() => {
						this.ajax.post('/admin/sys/user/resetPassword', this.passwordForm).then(() => {
							this.$message({
								message: this.$t('common.success'),
								type: 'success'
							})
							this.passwordVisible = false
						})
					})
				}
			})
		},
		del(row) {
			this.$confirm(
				this.$t('common.confirm') + this.$t('common.del') + this.$t('user.title') + '?',
				this.$t('common.Tips'),
				{
					confirmButtonText: this.$t('common.confirm'),
					cancelButtonText: this.$t('common.cancel'),
					closeOnClickModal: false,
					type: 'warning'
				}
			).then(() => {
				let form = {
					id: row.userId
				}
				this.ajax.post('/admin/sys/user/deleteSysUser', form).then(() => {
					this.$message({
						message: this.$t('common.success'),
						type: 'success'
					})
					this.query()
				})
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.LeftRight {
	display: flex;
	height: 400px;
	& div:nth-child(1) {
		overflow: auto;
		flex: 1;
	}
	& div:nth-child(2) {
		margin-left: 10px;
		overflow: auto;
		flex: 3;
	}
}
.el-button--text {
	cursor: pointer;
}
.textarea {
	width: 207px;
}
::v-deep .el-textarea__inner {
	width: 207px;
}
</style>
